import {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import BeyondWords from '@beyondwords/player'
import config from '@config'
import styled from 'styled-components'
import ErrorContainer from './ErrorContainer'

const {
  textToSpeech: { projectId },
} = config

export type TextToSpeechPlayerProps = {
  sourceId?: string
  contentId?: string
}

export type BeyondWordsPlayer = {
  destroy: () => void
  removeEventListener: (event: string) => void
}

const Wrapper = styled.div`
  height: 48px;
`

const TextToSpeechPlayer: FunctionComponent<TextToSpeechPlayerProps> = ({
  sourceId,
  contentId,
}) => {
  const playerRef = useRef(null)
  const [playerInstance, setPlayerInstance] =
    useState<BeyondWordsPlayer | null>(null)
  const [error, setError] = useState(false)

  const targetId = `beyondwords-player-${sourceId || contentId}`

  const noContentAvailableHandler = useCallback(() => {
    setError(true)
  }, [])

  useEffect(() => {
    if (playerRef.current && !playerInstance) {
      const player = new BeyondWords.Player({
        logoIconEnabled: false,
        target: `#${targetId}`,
        projectId,
        sourceId,
        contentId,
      })

      player.addEventListener('NoContentAvailable', noContentAvailableHandler)

      setPlayerInstance(player)
    }
  }, [playerInstance, sourceId, contentId, targetId, noContentAvailableHandler])

  useEffect(() => {
    return () => {
      playerInstance?.removeEventListener('NoContentAvailable')
      playerInstance?.destroy()
    }
  }, [playerInstance])

  return (
    <Wrapper>
      <div id={targetId} ref={playerRef} />
      {error && <ErrorContainer />}
    </Wrapper>
  )
}

const MemoizedTextToSpeechPlayer = memo(TextToSpeechPlayer)

MemoizedTextToSpeechPlayer.displayName = 'MemoizedTextToSpeechPlayer'

export default MemoizedTextToSpeechPlayer
