import SvgIcon from '@components/SvgIcon'
import { mobileCSS } from '@measures/responsive'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey100: grey100Color },
      },
    },
  }) => css`
    border-radius: 25px;
    background: ${grey100Color};

    height: 100%;
    box-sizing: border-box;
    padding: 6px;

    display: flex;
    align-items: center;
  `}
`

const ErrorMessage = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey800: grey800Color },
      },
      typography: {
        bodycopy: {
          xsmall: { bundledCSS: xsmallBodycopyCSS },
          xxsmall: { bundledCSS: xxsmallBodycopyCSS },
        },
      },
    },
  }) => css`
    border-radius: 25px;
    font-size: 14px;
    padding-left: 8px;
    color: ${grey800Color};

    ${xsmallBodycopyCSS};

    ${mobileCSS(css`
      ${xxsmallBodycopyCSS};
    `)}
  `}
`

const ErrorContainer: FunctionComponent = () => {
  return (
    <Wrapper>
      <SvgIcon iconName="player-inactive" size={40} />
      <ErrorMessage>Vorlesefunktion derzeit nicht verfügbar</ErrorMessage>
    </Wrapper>
  )
}

export default ErrorContainer
